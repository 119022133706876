<template>
  <auth-layout>
    <template v-slot:title>
      <span>{{ $t("create") }}</span>
      <span>{{ $t("account") }}</span>
    </template>
    <template v-slot:subtitle>
      <span>{{ $t("auth_form_create_account_text") }}</span>
    </template>
    <template v-slot:form>
      <v-form ref="form" class="" lazy-validation @submit.prevent="updateUser">
        <!-- email -->
        <v-text-field
          class="form-input"
          v-model.trim="userData.email"
          :label="$t('email')"
          :rules="[(val) => rules.email(val) || $t('auth_form_invalid_email')]"
          validate-on-blur
          @focus.stop="hideNotification"
          @focusout="checkUser"
          id="email"
          data-testid="email"
        ></v-text-field>
        <div class="user-name">
          <v-text-field
            class="form-input"
            v-model.trim="userData.first_name"
            :label="$t('first_name')"
            :rules="[
              () => !!userData.first_name || $t('auth_form_invalid_first_name'),
            ]"
            validate-on-blur
            id="first-name"
            data-testid="first-name"
          ></v-text-field>
          <v-text-field
            class="form-input"
            v-model.trim="userData.last_name"
            :label="$t('last_name')"
            :rules="[
              () => !!userData.last_name || $t('auth_form_invalid_last_name'),
            ]"
            validate-on-blur
            id="last-name"
            data-testid="last-name"
          ></v-text-field>
        </div>
        <div class="form-terms">
          <span>{{ $t("auth_form_terms_line") }}</span>
          <div class="terms-links">
            <a
              href="https://www.spartan.com/en/about/privacy-policy"
              target="_blank"
              class="terms-link"
              >{{ $t("privacy_policy") }}</a
            >
            <span class="links-divider">{{ $t("and") }}</span>
            <a
              href="https://www.spartan.com/en/about/terms-of-service"
              target="_blank"
              class="terms-link"
              >{{ $t("terms_of_use") }}.</a
            >
          </div>
        </div>
        <div class="form-buttons">
          <mem-button
            type="submit"
            btnType="secondary-dark"
            :disabled="disableBtn"
            :loading="loading"
          >
            {{ $t("sign_up_and_continue") }}
          </mem-button>
        </div>
      </v-form>
    </template>
    <template v-slot:footer>
      <span>{{ $t("already_have_account") }}</span>
      <span class="text-link" @click="goToSignIn">{{ $t("sign_in") }}</span>
    </template>
  </auth-layout>
</template>
<script>
import { emailValidator } from "@/common/validators";
import { mapGetters, mapActions } from "vuex";
import mixinAuthForms from "./mixinAuthForms";
export default {
  name: "FormSignUpApple",
  mixins: [mixinAuthForms],
  components: {
    "auth-layout": () => import("./AuthLayout.vue"),
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    rules: {
      email: emailValidator,
    },
    loading: false,
  }),
  computed: {
    ...mapGetters(["userData", "toolbarNotification", "unassignedTickets"]),
    disableBtn() {
      let { email, first_name, last_name } = this.userData;
      return (
        !emailValidator(email) ||
        !(first_name?.length > 0) ||
        !(last_name?.length > 0)
      );
    },
  },
  methods: {
    ...mapActions([
      "checkUserExist",
      "patchUserData",
      "handleToolbarNotification",
      "handleOverlay",
    ]),
    async updateUser() {
      this.hideNotification();

      let { email, first_name, last_name } = this.userData;
      let { lang, country } = this.$route.query;
      this.loading = true;

      let payload = {
        email,
        first_name,
        last_name,
        skip_reconfirmation: true,
      };
      if (lang) payload.language = lang;
      if (country) payload.spartan_country_code = country;

      try {
        let user = await this.patchUserData(payload);
        if (!user.spartan_country_code) this.handleOverlay("country");
        if (this.unassignedTickets.length !== 0)
          this.showTicketsDialog(this.$route.query.routeName);

        this.$router.push({
          path: this.$route.query.routePath,
          query: this.$route.query,
        });
      } catch (e) {
        this.loading = false;
        // if (e.multipass) this.$router.push("home");
        this.showExistNotification();
        console.log(e);
      }
    },
    goToSignIn() {
      this.hideNotification();
      this.$router.push({ name: "formSignIn", query: this.$route.query });
    },
    async checkUser() {
      let isValidEmail = emailValidator(this.userData.email);
      if (!isValidEmail) return;

      try {
        let user = await this.checkUserExist(this.userData.email);
        if (user.exists && this.$route.name === "formSignUpApple")
          this.showExistNotification();
      } catch (err) {
        console.log("", err);
      }
    },
    showExistNotification() {
      this.handleToolbarNotification({
        isVisible: true,
        body: "auth",
        data: {
          email: this.userData.email,
        },
      });
    },
    hideNotification() {
      this.handleToolbarNotification({ isVisible: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-name {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  @media screen and (max-width: 499px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}
.form-terms {
  // margin-bottom: 48px;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.4;
  @media screen and (max-width: 499px) {
    // margin-bottom: 44px;
  }
  .terms-links {
    .links-divider {
      margin: 0 5px;
    }
    .terms-link {
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-bottom: 2px;
      cursor: pointer;
      outline: none;
    }
  }
}

.form-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 34px;
  @media screen and (max-width: 499px) {
    margin-top: 44px;
    margin-bottom: 44px;
  }
  button {
    width: 100%;
  }
}
</style>